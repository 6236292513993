import { extendTheme } from "@chakra-ui/react"

const theme = {
   colors: {
      primary: "#C53030",
   },
   fonts: {
      body: "Outfit, sans-serif",
      heading: "Outfit, sans-serif",
   },
};

export default extendTheme(theme)